import React, { useState } from "react"
import { Box, Flex } from "rebass"
import ProvisionedCalc from "./Provisioned"
import OnDemand from "./OnDemand"
import { ExternalLink } from '@fragments';

const App = () => {
  const [isOnDemand, setOnDemandMode] = useState(false)
  return (
    <div style={{ marginBottom: "60px" }}>
      <Box pb={20}>
        <h2 style={{ marginTop: "62px" }}>Choose pricing model</h2>
        <Flex mx={-2} flexWrap="wrap">
          <Box p={2} width={[1, 1 / 2]}>
            <Box
              onClick={() => setOnDemandMode(true)}
              p={4}
              sx={{
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
                borderRadius: "5px",
                outline: isOnDemand ? "3px solid rgb(21, 144, 255)" : "0",
              }}
              className="shadow-hover"
            >
              <h3>On-Demand</h3>
              <p>
                Pay for WRU (Write Request Units) and RRU (Read Request Units).
                <br />
                <br />
                Priced $1.25 per million operations and $0.25 per million
                operations respectively.
              </p>
              <p style={{ fontSize: "0.65em" }}>
                For strongly consistent operations: <br />
                One WRU = 1 write operation with item size up to 1KB
                <br />
                One RRU = 1 read operation with item size up to 4KB per second
                <br />
                For eventually consistent divide by 2, for transactional
                multiply by 2
              </p>
            </Box>
          </Box>
          <Box p={2} width={[1, 1 / 2]}>
            <Box
              onClick={() => setOnDemandMode(false)}
              p={4}
              sx={{
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
                borderRadius: "5px",
                outline: !isOnDemand ? "3px solid rgb(21, 144, 255)" : "0",
              }}
              className="shadow-hover"
            >
              <h3>Provisioned Capacity</h3>
              <p>
                Pay for provisioned number of RCU (Read Capacity Units) and WCU
                (Write Capacity Units).
                <br /> <br />
                Billed $0.00013 per RCU/h and $0.00065 per WCU/h.
              </p>
              <p style={{ fontSize: "0.65em" }}>
                For strongly consistent operations: <br />
                One WRU = 1 write operation with item size up to 1KB <br />
                One RRU = 1 read operation with item size up to 4KB <br />
                For eventually consistent divide by 2, for transactional
                multiply by 2
              </p>
            </Box>
          </Box>
        </Flex>
      </Box>

      {isOnDemand ? <OnDemand /> : <ProvisionedCalc />}

      <p style={{ marginTop: "40px" }}>
        Don't know how many WCUs and RCUs get consumed by your item(s)? Use our{" "}
        <ExternalLink to="/dynamodb-capacity-and-item-size-calculator/">
          DynamoDB Item Size Calculator
        </ExternalLink>
      </p>
    </div>
  )
}

export default App
