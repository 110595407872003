export default [
  {
    name: "us-east-1 (N. Virginia)",
    wcuHour: 0.00065,
    rcuHour: 0.00013,
    storageGb: 0.25,
    wruMilion: 1.25,
    rruMilion: 0.25,
    streamReadRequestUnitPrice: 0.02, // per 100,000
  },
  {
    name: "us-east-2 (Ohio)",
    wcuHour: 0.00065,
    rcuHour: 0.00013,
    storageGb: 0.25,
    wruMilion: 1.25,
    rruMilion: 0.25,
    streamReadRequestUnitPrice: 0.02, // per 100,000
  },
  {
    name: "us-west-1 (N. California)",
    wcuHour: 0.000725,
    rcuHour: 0.000145,
    storageGb: 0.28,
    wruMilion: 1.3942,
    rruMilion: 0.279,
    streamReadRequestUnitPrice: 0.0224,
  },
  {
    name: "us-west-2 (Oregon)",
    wcuHour: 0.00065,
    rcuHour: 0.00013,
    storageGb: 0.25,
    wruMilion: 1.25,
    rruMilion: 0.25,
    streamReadRequestUnitPrice: 0.02, // per 100,000
  },
  {
    name: "ap-east-1 (Hong Kong)",
    wcuHour: 0.000814,
    rcuHour: 0.0001628,
    storageGb: 0.3135,
    wruMilion: 1.57,
    rruMilion: 0.31,
    streamReadRequestUnitPrice: 0.025, // per 100,000
  },
  {
    name: "ap-south-1 (Mumbai)",
    wcuHour: 0.00074,
    rcuHour: 0.000148,
    storageGb: 0.285,
    wruMilion: 1.4231,
    rruMilion: 0.285,
    streamReadRequestUnitPrice: 0.0228, // per 100,000
  },
  {
    name: "ap-northeast-3 (Osaka - Local)",
    wcuHour: 0.000742,
    rcuHour: 0.0001484,
    storageGb: 0.285,
  },
  {
    name: "ap-northeast-2 (Seoul)",
    wcuHour: 0.0007049,
    rcuHour: 0.00014098,
    storageGb: 0.27075,
    wruMilion: 1.3556,
    rruMilion: 0.271,
    streamReadRequestUnitPrice: 0.0217, // per 100,000
  },
  {
    name: "ap-northeast-1 (Tokyo)",
    wcuHour: 0.000742,
    rcuHour: 0.0001484,
    storageGb: 0.285,
    wruMilion: 1.4269,
    rruMilion: 0.285,
    streamReadRequestUnitPrice: 0.0228, // per 100,000
  },
  {
    name: "ap-southeast-1 (Singapore)",
    wcuHour: 0.00074,
    rcuHour: 0.000148,
    storageGb: 0.285,
    wruMilion: 1.4231,
    rruMilion: 0.285,
    streamReadRequestUnitPrice: 0.0228, // per 100,000
  },
  {
    name: "ap-southeast-2 (Sydney)",
    wcuHour: 0.00074,
    rcuHour: 0.000148,
    storageGb: 0.285,
    wruMilion: 1.4231,
    rruMilion: 0.285,
    streamReadRequestUnitPrice: 0.0228, // per 100,000
  },
  {
    name: "ca-central-1 (Canada)",
    wcuHour: 0.000715,
    rcuHour: 0.000143,
    storageGb: 0.275,
    wruMilion: 1.375,
    rruMilion: 0.275,
    streamReadRequestUnitPrice: 0.022, // per 100,000
  },
  {
    name: "eu-central-1 (Frankfurt)",
    wcuHour: 0.000793,
    rcuHour: 0.0001586,
    storageGb: 0.306,
    wruMilion: 1.525,
    rruMilion: 0.305,
    streamReadRequestUnitPrice: 0.0245, // per 100,000
  },
  {
    name: "eu-west-1 (Ireland)",
    wcuHour: 0.000735,
    rcuHour: 0.000147,
    storageGb: 0.283,

    wruMilion: 1.4135,
    rruMilion: 0.283,
    streamReadRequestUnitPrice: 0.0226, // per 100,000
  },
  {
    name: "eu-west-2 (London)",
    wcuHour: 0.000772,
    rcuHour: 0.0001544,
    storageGb: 0.29715,
    wruMilion: 1.4846,
    rruMilion: 0.297,
    streamReadRequestUnitPrice: 0.0237, // per 100,000
  },
  {
    name: "eu-west-3 (Paris)",
    wcuHour: 0.000772,
    rcuHour: 0.0001544,
    storageGb: 0.29715,
    wruMilion: 1.4846,
    rruMilion: 0.297,
    streamReadRequestUnitPrice: 0.0237, // per 100,000
  },
  {
    name: "eu-north-1 (Stockholm)",
    wcuHour: 0.000698,
    rcuHour: 0.00014,
    storageGb: 0.269,
    wruMilion: 1.3428,
    rruMilion: 0.269,
    streamReadRequestUnitPrice: 0.0215, // per 100,000
  },
  {
    name: "me-south-1 (Bahrain)",
    wcuHour: 0.0008085,
    rcuHour: 0.0001617,
    storageGb: 0.3113,
    wruMilion: 1.5549,
    rruMilion: 0.331,
    streamReadRequestUnitPrice: 0.0249, // per 100,000
  },
  {
    name: "sa-east-1 (São Paulo)",
    wcuHour: 0.000975,
    rcuHour: 0.000195,
    storageGb: 0.375,
    wruMilion: 1.875,
    rruMilion: 0.375,
    streamReadRequestUnitPrice: 0.03, // per 100,000
  },
]
