import React from "react"
import { Box } from "rebass"
import { ExternalLink } from "@fragments"
import FAQJsonLD from "@components/FAQJsonLD"

const PricingFAQ = () => (
  <Box mt={62}>
    <FAQJsonLD
      questions={[
        {
          question: "How expensive is DynamoDB?",
          answer:
            "DynamoDB costs can vary from being completely free to infinite depending on the amount of data you store and amount read/write throughput. Use our DynamoDB Costs Calculator above to estimate the costs of your tables.",
        },
        {
          question: "What pricing models are available for DynamoDB?",
          answer:
            "There are two basic pricing models for DynamoDB.  <strong>Provisioned Capacity</strong> - billed on an hourly basis per provisioned number of RCU (Read Capacity Units) and WCU (Write Capacity Units) Keep in mind that provisioned capacity supports autoscaling, so the number of capacity units can dynamically adapt to the traffic. Second, <strong>On-demand</strong> - billed on a request basis per WRU (Write Request Units) and RRU (Read Request Units). Truly Serverless offering where you pay only for the requests made.",
        },
        {
          question: "Is there a cost for individual DynamoDB tables?",
          answer: `<p>
          DynamoDB Tables on its own are totally free. However, you’re billed for:
        </p>
        <ul>
          <li>Amount of data stored</li>
          <li>Amount of data written and read</li>
          <li>Data transfer</li>
          <li>Backups and restore operations</li>
          <li>DynamoDB Streams</li>
          <li>Replicated write request units when using Global Tables</li>
        </ul>`,
        },
        {
          question: "What is DynamoDB GSI Cost?",
          answer:
            "Maintaining each Index adds 100 bytes of storage overhead per item, so your storage costs will be increased.",
        },
      ]}
    />
    <h2>Frequently Asked Questions</h2>
    <h3>How expensive is DynamoDB?</h3>
    <p>
      DynamoDB costs can vary from being completely free to infinite depending
      on the amount of data you store and amount read/write throughput. Use our
      DynamoDB Costs Calculator above to estimate the costs of your tables. Keep
      in mind that you can lower the costs a bit by applying the DynamoDB Free
      Tier.
    </p>
    <h3>What pricing models are available for DynamoDB?</h3>
    <p>There are two basic pricing models for DynamoDB.</p>
    <ol>
      <li>
        <strong>Provisioned Capacity</strong> - billed on an hourly basis per
        provisioned number of RCU (Read Capacity Units) and WCU (Write Capacity
        Units) Keep in mind that provisioned capacity supports autoscaling, so
        the number of capacity units can dynamically adapt to the traffic.
        Moreover, you can purchase{" "}
        <ExternalLink to={`/dynamodb-capacity-modes/#reserved-capacity`}>
          reserved capacity
        </ExternalLink>{" "}
        to obtain discounts in exchange for commitment to a certain usage level
        and upfront payment.
      </li>
      <br />
      <li>
        <strong>On-demand</strong> - billed on a request basis per WRU (Write
        Request Units) and RRU (Read Request Units). Truly Serverless offering
        where you pay only for the requests made. However, DynamoDB on demand
        pricing tends to be more expensive for production workloads.
      </li>
    </ol>
    <p>
      <ExternalLink to={`/dynamodb-capacity-modes/#provisioned-vs-on-demand`}>
        More details and differences here
      </ExternalLink>{" "}
    </p>
    <h3>Is there a cost for individual DynamoDB tables?</h3>
    <p>
      DynamoDB Tables on its own are totally free. However, you’re billed for:
    </p>
    <ul>
      <li>Amount of data stored</li>
      <li>Amount of data written and read</li>
      <li>Data transfer</li>
      <li>Backups and restore operations</li>
      <li>DynamoDB Streams</li>
      <li>Replicated write request units when using Global Tables</li>
    </ul>
    <h3>What is DynamoDB GSI Cost?</h3>
    <p>
      Maintaining each Index adds 100 bytes of storage overhead per item, so
      your storage costs will be increased.
    </p>
  </Box>
)

export default PricingFAQ
