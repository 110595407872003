import React from "react"
import { Box } from "rebass"
import { ExternalLink, InternalLink } from "@fragments"
import { learn } from "@constants/urls.js"

const CostOptimization = () => (
  <Box>
    <h2>DynamoDB Cost Optimization - Best Practices</h2>
    <p>
      Because Amazon DynamoDB is a fully managed database where a user does not
      provision any machines, the pricing is not as straight forward. While the
      tool above can help with forecasting the costs, the pricing optimization
      techniques are a bit different. Below are 10 tips that can help you lower
      your DynamoDB costs:
    </p>

    <h3>1. Use cheaper regions</h3>
    <p>
      If you are not concerned about your data's location because you don't need
      to meet any regulations or compliance standards, you can provision your
      tables in regions where it's cheaper. For example, if your table will have
      100GB of data, the storage will cost $28.50 per month in Tokyo
      (ap-northeast-1), $29.72 per month in London (eu-west-2), or even $37.50
      per month in Sao Paulo (sa-east-1).
      <br />
      <br />
      The cheapest regions are us-east-1, us-east-2 and us-west-2 costing $0.25
      per GB/month, $0.00065 per WCU/hour and $0.00013 per RCU/hour.
    </p>

    <h3>2. Use shorter attribute names</h3>
    <p>
      Because DynamoDB in both On-Demand and Provisioned capacity mode uses
      size-dependent billing units (1 WCU/WRU = 1KB, 1 RCU/RRU = 4KB), plus,
      you're paying for storage too, you should always aim to make your records
      as small as possible. If making attribute values is not an option, try
      making attribute names shorter. This helps you reduce the amount of
      storage required for your data. Moreover, when storing dates, you can
      choose the epoch time format instead of ISO dates because it's shorter
      too. It also makes your items compatible with{" "}
      <InternalLink to="/dynamodb-ttl/">DynamoDB's TTL feature</InternalLink>.
    </p>

    <h3>3. Be aware of huge blobs</h3>
    <p>
      Saving images in DynamoDB can quickly skyrocket costs. It's very
      inefficient, and you should rather store all images or linked assets in S3
      and save the URL pointing to it in DDB. If that's not an option, consider
      using compression algorithms like gzip to make blobs smaller before saving
      them.
    </p>

    <h3>4. Prefer queries over scans</h3>
    <p>
      DynamoDB has two ways of fetching a set of records from it:{" "}
      <InternalLink to="/dynamodb-query/">Query</InternalLink> and Scan. While
      the query is using partition and sort key to get the desired piece of data
      fast and directly, the scan, on the other hand, is "scanning" through your
      whole table. The difference here is that while in Query, you are charged
      only for items which are returned, in scan case, you're being charged for
      all the rows scanned, not the total amount of items returned.
    </p>

    <h3>5. Avoid strongly consistent reads and transactions where possible</h3>
    <p>
      DynamoDB uses eventually consistent data model. It means that updates are
      propagated across all storage locations, usually within one second or
      less. However, <InternalLink to={learn.whatIs}>DynamoDB</InternalLink>{" "}
      supports strongly consistent reads too, but with an additional cost.
      Strongly consistent reads require a double amount of Read request/capacity
      units than the eventually consistent reads.
    </p>

    <h3>6. When using GSIs, think about Attribute Projections</h3>
    <p>
      Attribute Projections specify which attributes are available when querying
      for data using{" "}
      <InternalLink to="/dynamodb-indexes/#global-secondary-index">
        Global Secondary Index
      </InternalLink>
      . Sometimes, when accessing the data using GSIs, not all attributes are
      needed. Reducing the amount of data available in GSIs by using Attribute
      Projection `KEYS_ONLY` or `INCLUDES` instead of `ALL` will reduce the
      amount of data kept in GSI significantly thereby lowering not only the
      costs storage but also consume less read/write units when accessing or
      updating the data.
    </p>

    <h3>7. Use on-demand mode wisely</h3>
    <p>
      <ExternalLink
        to={
          "https://hackernoon.com/understanding-the-scaling-behaviour-of-dynamodb-ondemand-tables-80d80734798f"
        }
      >
        As Yan Cui demonstrated in his blog post
      </ExternalLink>
      , "rough estimate suggests that on-demand tables are around 5–6 times more
      expensive per request compared to a provisioned table". Conclusion: if
      your workload has steady utilization without sudden spikes, consider going
      with provisioned mode with{" "}
      <InternalLink to="/dynamodb-on-demand-vs-provisioned-scaling/">
        autoscaling
      </InternalLink>{" "}
      enabled.
    </p>

    <h3>8. Use reserved capacity</h3>
    <p>
      When using provisioned capacity mode and your capacity is bigger than 100
      units, you can also consider purchasing{" "}
      <InternalLink to="/dynamodb-capacity-modes/#reserved-capacity">
        reserved capacity
      </InternalLink>
      . For a three year term, reserved capacity provides a 76% discount, and
      for a one year term, reserved capacity provides a 53% discount when
      compared to provisioned throughput capacity.
    </p>

    <h3>9. Remove unnecessary items</h3>
    <p>
      To minimize the storage costs, aim to always purge unnecessary data. If
      your business does not have to keep the events older than X days, you can
      leverage the{" "}
      <ExternalLink to="https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/TTL.html">
        TTL
      </ExternalLink>{" "}
      functionality.
      <br /> <br />
      Moreover, if you have any many-to-many relationships, remember that you
      need to take care of "orphaned records" on your own. As DynamoDB is not a
      relational database, it does not have an `ON DELETE CASCADE` feature like
      many RDBMS.
    </p>

    <h3>10. Do not overuse GSIs</h3>
    <p>
      DynamoDB automatically copies the right set of attributes to any indices
      where the attributes must exist. This increases the costs of your storage
      because of the additional space used. In detail, it is a sum of:
    </p>
    <ul>
      <li>Byte size of table primary key</li>
      <li>Byte size of index key attribute</li>
      <li>Byte size of projected attributes</li>
      <li>100 byte-overhead per index item</li>
    </ul>
  </Box>
)

export default CostOptimization
