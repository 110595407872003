import React, { useState } from "react"
import { Box, Text } from "rebass"
import Button from "@components/button"
import { download } from "@constants/urls.js"

const titles = [
  "Still using AWS DynamoDB Console?",
  "Using DynamoDB Console frequently?",
  "Dynobase - DynamoDB Swiss Army Knife",
  "Tired of AWS Console?",
  "Login to the AWS Console less.",
  "Better DynamoDB Experience.",
]

const SideCTA = props => {
  const [isVisible, setVisibility] = useState(true)

  if (!isVisible) {
    return <div></div>
  }

  return (
    <div
      style={{ position: "fixed", bottom: "20px", right: "20px" }}
      className="side-cta"
    >
      <Box
        style={{
          backgroundColor: "white",
          boxShadow:
            "0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.35)",
          padding: "25px",
          borderRadius: "10px",
          maxWidth: "350px",
          position: "relative",
        }}
      >
        <Text
          style={{
            position: "absolute",
            right: "10px",
            top: "0px",
            transform: "rotate(-45deg)",
            cursor: "pointer",
          }}
          onClick={() => setVisibility(false)}
        >
          +
        </Text>
        <Text fontWeight="700" fontSize="1.2em" mb={0}>
          {titles[Math.floor(Math.random() * titles.length)]}
        </Text>
        <Text
          my={"10px"}
          fontSize="0.75em"
          color="#555"
          style={{ lineHeight: "1.5" }}
        >
          Try <b>Dynobase</b> to accelerate your DynamoDB workflow. Start your
          7-day free trial today.
        </Text>
        <Button
          primary
          href={download}
          linkClassName="dynobase-download-button"
        >
          Download Now
        </Button>
      </Box>
    </div>
  )
}

export default SideCTA
