import React, { useState } from "react"
import { Box } from "rebass"
import regions from "./regions"

const OnDemand = () => {
  const [wru, setWru] = useState(1000000)
  const [rru, setRRu] = useState(1000000)
  const [storage, setStorage] = useState(25)
  const [streamReadRequestUnits, setStreamReadRequestUnits] = useState(0)
  const [isFreeTierApplied, setFreeTier] = useState(true)
  const [pricing, setPricing] = useState(regions[0])

  const storageGb = pricing.storageGb
  const streamReadRequestUnitPrice = pricing.streamReadRequestUnitPrice
  const wruMil = pricing.wruMilion
  const rruMil = pricing.rruMilion

  return (
    <Box
      m={2}
      px={4}
      pt={4}
      pb={2}
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
        borderRadius: "5px",
      }}
    >
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        In which region is the Table going to be hosted?
      </h4>

      <select
        id="regions"
        value={pricing.name}
        onChange={e => setPricing(regions.find(r => r.name === e.target.value))}
      >
        {regions.map(region => (
          <option value={region.name}>{region.name}</option>
        ))}
      </select>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        How many WRUs (Write request units) will your application perform in a
        month?
      </h4>
      <input
        type="number"
        value={wru}
        onChange={e => setWru(e.target.value)}
        style={{ width: "120px" }}
      ></input>
      <p style={{ display: "inline", marginLeft: "10px" }}>
        This will allow you {wru} Strongly Consistent writes of items up to 1KB
        per month{" "}
      </p>
      <p style={{ fontSize: "0.8em", color: "#666" }}>
        (${((wru * wruMil) / 1000000).toFixed(2)} per month)
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "15px",
        }}
      >
        How many RRUs (Read request units) will your application perform in a
        month?
      </h4>
      <input
        value={rru}
        onChange={e => setRRu(e.target.value)}
        style={{ width: "120px" }}
        type="number"
      ></input>
      <p style={{ display: "inline", marginLeft: "10px" }}>
        This will allow you {rru} Strongly Consistent reads of items up to 4KB
        per month{" "}
      </p>
      <p style={{ fontSize: "0.8em", color: "#666" }}>
        (${((rru * rruMil) / 1000000).toFixed(2)} per month)
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "15px",
        }}
      >
        How much data will there be in the table?
      </h4>
      <input
        value={storage}
        onChange={e => setStorage(e.target.value)}
        style={{ width: "120px" }}
        type="number"
      ></input>
      <p style={{ display: "inline", marginLeft: "10px" }}>GB</p>
      <p style={{ fontSize: "0.8em", color: "#666" }}>
        (${(storageGb * storage).toFixed(2)} per month)
      </p>

      <h4
        style={{
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        How much data (in RRU) will be read from DynamoDB Streams?
      </h4>
      <p
        style={{
          fontSize: "0.8em",
          color: "#666",
          marginTop: 0,
          marginBottom: "10px",
        }}
      >
        Data from DynamoDB Streams is read using GetRecords API call. Each of
        these calls is billed on RRU basis and returns up to 1MB of data. Stream
        RRU are independent from Table RRU.
      </p>
      <input
        value={streamReadRequestUnits}
        onChange={e => setStreamReadRequestUnits(e.target.value)}
        style={{ width: "120px" }}
        type="number"
      ></input>
      <p style={{ display: "inline", marginLeft: "10px" }}>units per month</p>
      <p style={{ fontSize: "0.8em", color: "#666" }}>
        ($
        {(
          (streamReadRequestUnitPrice * streamReadRequestUnits) /
          100000
        ).toFixed(2)}{" "}
        per month)
      </p>

      <p style={{ marginTop: "10px" }}>
        <input
          type="checkbox"
          name="free-tier"
          checked={isFreeTierApplied}
          onChange={() => setFreeTier(e => !e)}
        />
        <label for="free-tier" style={{ marginLeft: "10px" }}>
          Apply AWS Free Tier?
        </label>
        <p style={{ fontSize: "0.8em", marginTop: "0px", color: "#666" }}>
          Includes free 25 GB of data storage and 2,500,000 DynamoDB Streams
          read requests
        </p>
      </p>

      <h3 style={{ marginBottom: "0" }}>
        ~
        {(
          (isFreeTierApplied ? Math.max(storage - 25, 0) : storage) *
            storageGb +
          (wruMil / 1000000) * wru +
          (rruMil / 1000000) * rru +
          ((isFreeTierApplied
            ? Math.max(streamReadRequestUnits - 2500000, 0)
            : streamReadRequestUnits) *
            streamReadRequestUnitPrice) /
            100000
        ).toFixed("2")}{" "}
        USD per month
      </h3>
      <p style={{ fontSize: "0.7em", marginTop: "10px", color: "#666" }}>
        Additional charges related to Data Transfer, Backups, DAX and Global
        Tables might apply depending on usage.
      </p>
    </Box>
  )
}

export default OnDemand
