import React from "react"
import { Box } from "rebass"

const Intro = () => (
  <Box mt={62}>
    <h1 style={{ letterSpacing: -1 }}>DynamoDB Pricing Calculator</h1>
    <p style={{ marginBottom: "0.8em" }}>
      DynamoDB pricing is a double-edged sword. While it provides infinite
      scalability, it can also drain out your wallet pretty quickly. Forecast
      your DynamoDB costs with this simple calculator.
    </p>
  </Box>
)

export default Intro
