import React from "react"
import { FooterWithCTA, SEO, Layout } from "@components"
import PricingCalculator from "@components/pricingCalculator/App"
import Intro from "@components/pricingCalculator/Intro"
import FAQ from "@components/pricingCalculator/FAQ"
import CostOptimization from "@components/pricingCalculator/CostOptimization"
import SideCTA from "@components/SideCTA"
import { Container } from "@components"

const PricingCalculatorPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DynamoDB Pricing &amp; Cost Calculator (Free Tool)"
          description="Wondering how expensive is DynamoDB? Use this simple DynamoDB pricing calculator to estimate the cost of using DDB"
          canonical="https://dynobase.dev/dynamodb-pricing-calculator/"
        />
        <div>
          <Container>
            <Intro />
            <PricingCalculator />
            <CostOptimization />
            <FAQ />
          </Container>
          <FooterWithCTA />
        </div>
      </Layout>
      <SideCTA />
    </>
  )
}

export default PricingCalculatorPage
